//using #root as wrapper definition to give all more specifity in order to properly overwrite other css files
//at least until nextjs import order issue is fixed
#root {
  .mobile, .table {
    display: none;
  }
}

@media screen and (max-width: 1265px) {
  #root {
    #partners .content {
      padding-bottom: 25px;
      >p {
        margin-bottom: 0;
      }
    }
    #partners-list {
      justify-content: space-evenly;
      .partners-list-item {
        height: 125px;

        &.partner-gestalten,
        &.partner-unikrn {
          height: 100px;
          >div.partner-image {
            background-size: 100% auto !important;
          }
        }
        &.partner-crosslend {
          >div.partner-image {
            background-position: center -8px;
          }
        }
        &.partner-flatnut {
          height: 100px;
          margin-bottom: 30px;
          >div.partner-image {
            background-size: contain !important;
          }
        }

        &:hover div.partner-image {
          background-position: center;
        }
        div.partner-image .partner-detail {
          height: 100px;
          bottom: -100px;
        }
      }
    }
    #themes-list {
      max-width: 100%;
    }
  }
}
@media screen and (max-width: 1060px) {
  #root {
    #navigation .navbar ul#nav-items {
      display: none;
    }
    .table {
      display: block;
    }
    #nav-mobile-btn {
      height: 65px;
      font-size: 24px;
      max-width: 22px;
      color: #333;
    }
  }
}
@media screen and (max-width: 968px) {
  #root {
    #partners-list {
      justify-content: center;
      .partners-list-item {
        div.partner-image .partner-detail {
          height: 80px;
          bottom: -80px;
        }
      }
    }
    #contact .content-title {
      font-size: 64px;
    }
    #themes-list  {
      justify-content: center;
      .theme-item {
        min-width: 250px;
        padding: 20px;
      }
    }
  }
}

/* TABLET */
@media screen and (max-width: 780px) {
  #root {
    section.content-wrap .content {
      padding: 75px 20px;
    }
    #main-intro .parallax-window .pattern > div.flex-container div.text-slide-wrap ul.text-slide-vertical  {
      font-size: 40px;
    }
    #contact .content-title {
      font-size: 46px;
    }
  }
}

/* =============== */
/* PHONE */
@media screen and (max-width: 560px) {
  #root {
    .filler {
      height: 60px;
    }

    #career {
      p {
        margin-top: 28px;
        line-height: 20px;
      }
      button {
        margin-top: 30px;
      }
    }
    #contact .content-title {
      font-size: 36px;
    }
    section.content-wrap .content {
      padding: 30px 20px;
    }
  }
}

/* =============== */
/* Small PHONE */
@media screen and (max-width: 450px) {
  #root {
    #contact .content-title {
      font-size: 22px;
      padding: 0;
      margin: 0 auto;
    }
  }
}
