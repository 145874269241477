body {
  margin: 0;
  font-family: var(--font-montserrat), sans-serif;

  #main {
    position: relative;
    z-index: 1;
  }
}

li {
  list-style: none;
}

.border {
  background: none repeat scroll 0 0 #555;
  font-family: var(--font-open_sans), sans-serif;
  display: block;
  height: 2px;
  width: 100px;
  margin: 0 auto;
}

strong {
  color: #222222;
  display: inline-block;
}

button {
  border: none;
  background-color: #222222;
  padding: 10px 15px;
  line-height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 3px;
  transition: opacity 400ms;
  opacity: 1;

  &:hover {
    opacity: 0.5;
  }
}

.clickable, a {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
.not_clickable {
  pointer-events: none;
  cursor: default;
}

.circle {
  border: 2px solid #fff;
  width: fit-content;
  border-radius: 50%;
  padding: 1rem 1.25rem;
}
.push-left {
  margin-left: 20px !important;
}
.push-right {
  margin-right: 20px !important;
}

.site {
  display: none;

  &.active {
    display: block;
  }
}

/* === Section === */
section.content-wrap {
  background-color: #f5f5f5;
  z-index: 1;

  .content {
    text-align: center;
    width: 700px;
    max-width: calc(100% - 40px); //do keep padding in line
    overflow: hidden;
    margin: 0 auto;
    padding: 100px 20px;

    .content-title {
      font-size: 36px;
      letter-spacing: 3px;
      color: #222222;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 15px;
      font-weight: 400;
      line-height: 40px;
    }
    p {
      font-size: 14px;
      color: #555;
    }
    .border {
      margin-bottom: 40px;
    }
  }

  &.white {
    background-color: #fff;
  }
  &.dark {
    background-color: rgba(0, 0, 0, 0.80);
    color: #fff;
    a, span, .content-title {
      color: #fff;
    }
  }

  &.text-site {
    .content {
      width: 1180px;
      max-width: 90%;

      .container {
        text-align: left;
        h1 {
          font-size: 36px;
          letter-spacing: 3px;
          color: #222222;
          text-transform: uppercase;
          margin-bottom: 15px;
        }
        h3, h2 {
          font-weight: 400;
          font-size: 24.5px;
          text-transform: uppercase;
          color: #222222;
          margin-bottom: 0;
        }
        h2 {
          font-size: 31.5px;
        }
        p {
          font-size: 14px;
          margin: 0;
        }
        ul {
          padding-left: 20px;
          font-size: 14px;
          color: #555;
          margin: 5px 0;
          li {
            list-style: circle;
          }
        }

        a {
          color: #222;
          text-decoration: none;
        }
      }
    }
  }
}
/* ==== Section END ==== */

/* ==== Flex ==== */
.flex-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  .full-width {
    width: 100%;
    margin: 0;
  }

  .half-width {
    width: 49%;
  }

  .third-width {
    width: 32%;
    min-width: fit-content;
    max-width: 400px;

    input, select, textarea {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .quarter-width {
    width: 24%;
    min-width: fit-content;
    max-width: 400px;

    input, select, textarea {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    &.checkbox {
      margin-top: 10px;
    }
  }

  &.flex-end {
    justify-content: flex-end;
  }

  &.flex-start {
    justify-content: flex-start;
  }

  &.space-around {
    justify-content: space-around;
  }
  &.flex-center {
    justify-content: center;
    >div:not(first-of-type) {
      margin-left: 10px;
    }
  }

  &.align-end {
    align-items: flex-end;

    .btn {
      margin-bottom: 15px;
    }
  }
}

/* ==== Flex End ==== */

/* ==== Navigation ==== */
#navigation {
  height: 65px;
  background: #fff;
  width: 100%;
  z-index: 100;

  &.fixed {
    position: fixed;
    top: 0;
  }

  .navbar {
    transition: all 0.3s ease-out 0s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: initial;

    #logo {
      display: flex;
      justify-content: center;
      flex-direction: column;
      max-width: 60%;

      img {
        height: auto;
        max-width: 100%;
      }
    }

    ul#nav-items {
      display: flex;
      margin: 0;

      li {
        line-height: 45px;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 12px;

        cursor: pointer;

        a, span {
          display: block;
          padding: 10px 15px 10px;
          text-decoration: none;
          color: #555;
        }

        &:hover {
          opacity: 0.8;
          a, span {
            font-weight: 700;
            color: #000;
          }
        }
      }
    }
  }
}
#nav-mobile {
  background-color: #f5f5f5;
  padding: 0 30px;
  width: 100%;

  ul {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      width: 100%;
      border-bottom: 1px dotted #CCCCCC;
      list-style: none;
      padding: 8px 0;
      font-size: 12px;
      text-transform: uppercase;
      line-height: 25px;
      cursor: pointer;
      color: #555;
      transition: color 200ms;

      &:hover {
        color: #222;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}

#nav-puffer {
  display: block;
  height: 65px;
  width: 100%;
}

/* ==== Navigation END ==== */

/* ==== Footer ==== */
#footer {
  box-shadow: 0px -1px 5px rgb(0 0 0 / 15%);
  z-index: 100;
  position: relative;
  padding: 40px 0;

  a, span {
    font-size: 14px;
    color: #555;
    text-decoration: none;
  }
  a:not(:last-child), span {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #555;
  }

  #pulse-arrows {
    height: 50px;
    width: 40px;
    border-radius: 3px;
    left: 50%;
    margin-left: -20px;
    margin-top: -65px;
    position: absolute;
    cursor: pointer;
    background-color: #222;

    >div {
      color: #fff;
      max-width: 18px; //same as svg width
      margin: 0 auto;
      position: relative;
      height: 50px; //need a fixed size, cause fontawesome svg have too much padding, have to overlap via absolute

      >svg,img {
        position: absolute;
        width: 18px;
        color: #fff;
        overflow: hidden;
        height: 22px;

        &.one {
          top: 5px
        }
        &.two {
          top: 15px;
        }
        &.three {
          top: 25px;
        }
      }
    }
    &:hover {
      img, svg {
        animation: pulse 1s infinite;

        &.one {
          animation-delay: 0.3s;
          animation-direction: alternate;
        }
        &.two {
          animation-delay: 0.2s;
          animation-direction: alternate;
        }
        &.three {
          animation-delay: 0.1s;
          animation-direction: alternate;
        }
      }
    }
  }
}
@keyframes pulse {
  0%   { opacity: 0; }
  50%  { opacity: 0.5; }
  100% { opacity: 1; }
}
/* ==== Footer End ==== */