//TODO improve: With nextjs could be split again in separate files
/* ==== Landing ==== */
.filler {
  background-color: rgba(0, 0, 0, 0.80);
  height: 120px;
}

#what-you-see {
  background-color: #f5f5f5;
  height: 116px;
  text-align: center;
  text-transform: uppercase;
  font-size: 17.5px;
  line-height: 35px;

  display: flex;
  justify-content: center;
  flex-direction: column;

  h1 {
    margin: 0;
    font-size: 17.5px;
    font-weight: 400;

    span {
      vertical-align: bottom;
    }
  }

  button {
    margin-left: 20px;
  }
}

/* ==== Main Intro ==== */
#main-intro {
  .parallax-window {
    height: 100%;
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: transparent;

    .react-parallax-background-children {
      height: 100%;
      width: 100%;
    }

    .pattern {
      background: url(/images/pattern.webp) repeat;
      width: 100%;

      > div.flex-container {
        flex-direction: column;
        justify-content: center;
        min-height: 100vh; //<-- important to cover initial screen
        text-align: center;

        div.text-slide-wrap {
          overflow: hidden;
          max-width: 100%;
          height: 0;
          transition: height 500ms;

          ul.text-slide-vertical {
            position: relative;

            color: #fff;
            font-size: 70px;
            text-transform: uppercase;
            margin: 0;
            padding: 0;
          }
        }

        .main-intro-logo {
          background-color: rgba(255, 255, 255, 0.2);
          margin: 20px auto 0;
          padding: 10px 15px;
          max-width: calc(100% - 30px); //dont forget padding

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

/* ==== Main END ==== */

/* ==== Partners ==== */
#partners-list {
  .partners-list-item {
    filter: grayscale(1);
    transition: filter 500ms;

    min-width: 250px;
    width: 20%;
    max-width: 100%;
    margin: 0;
    height: 265px;

    text-align: center;

    div.partner-image {
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;

      transition: background-position 500ms;
      overflow: hidden;

      .partner-detail {
        height: 80px;
        width: 100%;
        position: absolute;
        bottom: -80px;
        transition: all 0.3s ease 0s;
        background-color: #222;
        color: #fff;
        font-weight: 400;
        display: flex;
        justify-content: center;
        flex-direction: column;

        p {
          font-size: 14px;
          padding: 0;
          width: 100%;

          > span {
            font-size: 12px;
          }
        }
      }
    }

    &:hover {
      filter: grayscale(0);

      div.partner-image {
        background-position: center -50px;

        .partner-detail {
          bottom: 0;
        }
      }
    }
  }
}

/* ==== Partners END ==== */
#whatsetsusapart .content {
  padding-bottom: 15px;
}

/* ==== Themes ==== */
#themes-list {
  width: 1180px;
  margin: 0 auto 100px auto;
  text-align: center;

  .theme-item {
    width: 20%;
    transition: all 250ms;
    position: relative;
    padding: 30px 20px;
    overflow: hidden;

    p {
      margin-top: 10px;
      font-size: 13px;
      color: #555;
    }
    h2 {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 17.5px;
      color: #222;
      margin-top: 10px;
    }

    &:hover {
      background-color: #222;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        border-width: 0 25px 25px 0;
        border-style: solid;
        display: block;
        width: 0;
        border-color: #fff #fff #4e4e4e #4e4e4e;
      }
      p {
        color: rgba(255,255,255,0.5);
      }
      h2, h4 {
        color: #fff;
      }
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}
/* ==== Themes END ==== */

/* ==== Career ==== */
#career {
  background-color: #f5f5f5;
  text-align: center;
  font-size: 14px;
  line-height: 30px;

  p {
    margin-bottom: 0;
  }

  button {
    margin-top: 60px;
    font-size: 16px;
    padding: 15px 25px;
    line-height: 16px;
    width: 155px;
  }
}
/* ==== Career END ==== */

/* ==== Contact ==== */
#contact {
  .circle {
    margin: 0 auto;
    font-size: 32px;
  }

  a, span {
    text-decoration: none;
  }
  a:hover {
    color: #fff;
  }

  .content-title {
    text-align: center;
    color: #fff;
    font-size: 72px;
    line-height: 72px;
    padding: 10px 20px;
    margin: 30px 0 20px;
    white-space: nowrap;
    display: block;
    width: calc(100% - 40px);
    &:hover {
      opacity: 0.8;
    }
  }

  .icon-text {
    position: relative;
    margin-bottom: 15px;

    >div {
      display: block;
      width: 100%;
      img {
        margin-bottom: 5px;
        margin-top: 20px;
        vertical-align: top;
      }
    }

    &:last-of-type { margin: 0; }
  }
}
/* ==== Contact END ==== */

/* ==== Map ==== */
#map {
  max-width: 100%;
  overflow: hidden;
  .active { display: block; }

  >div {
    position: relative !important;
    width: 100% !important;
    height: 450px !important;
  }
}
/* ==== Map END ==== */

/* ==== Landing END ==== */
